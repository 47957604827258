import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { ComAPIService } from '../../API/com-api.service';
import { Subject, take, takeUntil, tap } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-my-scripts',
  templateUrl: './my-scripts.component.html',
})
export class MyScriptsComponent implements OnInit, OnDestroy {
  scripts: any;
  loading = true;
  buttonsDisabled = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private service: ComAPIService,
    private ref: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.updateScripts();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  updateScripts() {
    this.service
      .getScriptsInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.scripts = value;
        this.loading = false;
        this.checkIfScriptWorking(value);
        // console.log(value);
        this.ref.detectChanges();
      });
  }
  checkIfScriptWorking(scripts: any) {
    scripts.map((item: any) => {
      if (item.status) {
        this.buttonsDisabled = true;
      }
    });
  }
  runScript(name: string) {
    this.service
      .getScrapper(name)
      .pipe(
        take(1),
        tap(() => {
          this.buttonsDisabled = true;
          this.scripts.map((item: any) => {
            if (item.name === name) {
              item.status = true;
            }
          });
          this.ref.detectChanges();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
    console.log(name);
  }

  resetScripts() {
    this.service
      .getScrapper('All')
      .pipe(
        take(1),
        tap(() => {
          this.buttonsDisabled = false;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.updateScripts());
  }
}
